<template>
  <section class="content">
    <div class="overlay-wrapper">
      <form class="row" role="form" @submit.prevent="submitForm">
        <div class="col-lg-6 mt-3">
          <div class="card border rounded mb-3">
            <div class="card-body">
              <div class="h5 font-weight-bold pb-2 border-bottom mb-3">Informasi Pengiriman</div>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>Alamat Pengirim</label>
                  </div>
                </div>
                <div class="form-control d-flex justify-content-between align-items-center h-100 py-3 cursor-pointer" v-on:click="selectWarehouse" v-bind:class="{ 'is-invalid': sender.error }">
                  <div v-if="sender.id" class="d-flex flex-column" style="gap: 6px">
                    <div class="font-weight-bold">{{ sender.senderName }}</div>
                    <div>{{ sender.senderTel }} ({{ sender.pic_name }})</div>
                    <div>{{ sender.addr_detail }}</div>
                    <div>{{ sender.kecamatan }}, {{ sender.kota }}, {{ sender.kodepos }}</div>
                  </div>
                  <div v-else class="text-muted">
                    Masukkan alamat pengirim
                  </div>
                  <i class="fi fi-rr-edit text-primary" style="font-size: larger;"></i>
                </div>
              </div>
              <div class="form-group mb-3">
                <div class="row">
                  <div class="col">
                    <label class="font-weight-normal">Atur pengirimanmu?</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-control h-100" v-bind:class="{ 'border-primary' : form.order_type == 0 }" v-bind:style="{ backgroundColor: form.order_type == 0 ? '#ffe1c9' : '#fff' }" style="border-width: 2px;">
                      <div class="icheck-material-orange w-100">
                        <input type="radio" id="order_type" name="order_type" v-model="form.order_type" value="0" @change="onChange($event)" required :disabled="og_status == 1" />
                        <label for="order_type" class="form-check-label w-100">Pickup</label>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-control h-100" v-bind:class="{ 'border-primary' : form.order_type == 1 }" v-bind:style="{ backgroundColor: form.order_type == 1 ? '#ffe1c9' : '#fff' }" style="border-width: 2px;">
                      <div class="icheck-material-orange w-100">
                        <input type="radio" id="order_type2" name="order_type" v-model="form.order_type" value="1" @change="onChange($event)" required :disabled="droppOffDisabled" />
                        <!-- <input type="radio" id="order_type2" name="order_type" v-model="form.order_type" value="1" @change="onChange($event)" required :disabled="true" /> -->
                        <label for="order_type2" class="form-check-label w-100">Drop Off</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="form-group mb-3" v-if="form.order_type == 0">
                <div class="row">
                  <div class="col">
                    <label class="font-weight-normal">Mau dijemput pakai apa?</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-control h-100" v-bind:class="{ 'border-primary' : form.pickup_via == 1 }" v-bind:style="{ backgroundColor: form.pickup_via == 1 ? '#ffe1c9' : '#fff' }" style="border-width: 2px;">
                      <div class="icheck-material-orange w-100">
                        <input type="radio" id="pickup_via1" name="pickup_via" v-model="form.pickup_via" value="1" required :disabled="og_status == 1" />
                        <label for="pickup_via1" class="form-check-label w-100">Motor</label>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-control h-100" v-bind:class="{ 'border-primary' : form.pickup_via == 2 }" v-bind:style="{ backgroundColor: form.pickup_via == 2 ? '#ffe1c9' : '#fff' }" style="border-width: 2px;">
                      <div class="icheck-material-orange w-100">
                        <input type="radio" id="pickup_via2" name="pickup_via" v-model="form.pickup_via" value="2" required :disabled="og_status == 1" />
                        <label for="pickup_via2" class="form-check-label w-100">Mobil</label>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-control h-100" v-bind:class="{ 'border-primary' : form.pickup_via == 3 }" v-bind:style="{ backgroundColor: form.pickup_via == 3 ? '#ffe1c9' : '#fff' }" style="border-width: 2px;">
                      <div class="icheck-material-orange w-100">
                        <input type="radio" id="pickup_via3" name="pickup_via" v-model="form.pickup_via" value="3" required :disabled="og_status == 1"/>
                        <label for="pickup_via3" class="form-check-label w-100">Truck</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="form-group mb-3" v-if="form.order_type == 0">
                <div class="row">
                  <div class="col">
                    <label class="font-weight-normal">Waktu penjemputanmu?</label>
                  </div>
                </div>
                <input class="form-control" style="height: calc(1.79rem + 25px);" ref="daterange"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-3">
          <div class="card border rounded mb-3">
            <div class="card-body">
              <div class="h5 font-weight-bold pb-2 border-bottom mb-3">Download Template</div>
              <div class="form-group">
                <div class="form-control bos-alert-warning lh-1 d-flex align-items-center h-100 w-100">
                  <div class="row">
                    <div class="col-auto d-flex align-items-center">
                      <i class="fi fi-rr-info d-flex align-items-center"></i>
                    </div>
                    <div class="col d-flex align-items-center">
                      <div class="text-md">
                        Download template .xlsx untuk melengkapi detail pesanan. Kemudian upload template yang sudah terisi.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <div class="form-group mb-0">
                  <div class="row">
                    <div class="col-8">
                      <button type="button" @click="downloadTemplate"
                        class="btn btn-outline-primary btn-block py-3">
                        Unduh Template
                        <span v-if="loadingDownload" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span>
                      </button>
                    </div>
                    <div class="col-4">
                      <button type="button" @click="downloadKodeXlsx"
                        class="btn btn-outline-primary btn-block py-3">
                        Kode Tujuan
                        <span v-if="loadingDownload2" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card border rounded mb-3">
            <div class="card-body">
              <div class="h5 font-weight-bold pb-2 border-bottom mb-3">Upload Template</div>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <div class="form-control h-100" v-bind:class="{ 'border-primary' : form.order_online == 0 }" v-bind:style="{ backgroundColor: form.order_online == 0 ? '#ffe1c9' : '#fff' }" style="border-width: 2px;">
                      <div class="icheck-material-orange w-100">
                        <input type="radio" id="boscod" name="order_online" v-model="form.order_online" value="0" @change="onChangeOrderOnline($event)" required :disabled="og_status == 1" />
                        <label for="boscod" class="form-check-label w-100">bosCOD</label>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-control h-100" v-bind:class="{ 'border-primary' : form.order_online == 1 }" v-bind:style="{ backgroundColor: form.order_online == 1 ? '#ffe1c9' : '#fff' }" style="border-width: 2px;">
                      <div class="icheck-material-orange w-100">
                        <input type="radio" id="order_online" name="order_online" v-model="form.order_online" value="1" @change="onChangeOrderOnline($event)" required :disabled="og_status == 1" />
                        <label for="order_online" class="form-check-label w-100">Order Online</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="form-control bos-alert-primary lh-1 d-flex align-items-center h-100 w-100">
                  <div class="row">
                    <div class="col-auto d-flex align-items-center">
                      <i class="fi fi-rr-info d-flex align-items-center"></i>
                    </div>
                    <div class="col d-flex align-items-center">
                      <div class="text-md">
                        Kamu hanya bisa upload template {{form.order_online == 1 ? "order online" : "boscod"}}.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group mb-3">
                <div class="row">
                  <div class="col">
                    <form action="/target" ref="dzone" class="dropzone card" style="border-style: dashed;">
                      <div class="overlay" v-if="this.loading">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <div class="dz-message text-muted" data-dz-message>
                        <h4>
                          <div><i class="fi fi-rr-document" style="font-size: 50px;"></i></div>
                          <span>{{file_name == null ? 'Drag & Drop, or Browse Files' : file_name}}</span>
                        </h4>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="form-group mb-3" v-if="og_status != 1">
                <div class="row">
                  <div class="col">
                    <button type="submit" id="submit"
                      class="btn btn-primary btn-block py-3" :disabled="this.disableSubmit">
                      Klik Untuk Upload
                      <span v-if="submitting1" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <Warehouse :show="show" v-model="sender" />
  </section>
</template>

<script>
  import Vue from "vue";
  import XLSX from "xlsx";
  import {authFetch} from "@/libs/hxcore";
  import Swal from "sweetalert2";
  import $ from "jquery";
  import ToggleButton from "vue-js-toggle-button";
  import Warehouse from "@/dialog/Warehouse";
  import "dropzone/dist/dropzone.css";
  import "vue-select/dist/vue-select.css";
  import datepicker from "@/components/Datepicker";
  import timepicker from "@/components/Timepicker";
  import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
  import moment from "moment";
  import Daterangepicker from "daterangepicker";
  import "icheck-material";
  import "daterangepicker/daterangepicker.css";
  import Dropzone from "dropzone";
  import "dropzone/dist/dropzone.css";

  Vue.use(ToggleButton);

  export default {
    data() {
      return {
        loadingDownload: false,
        loadingDownload2: false,
        shouldDisable: false,
        addrOptions: [],
        loading: false,
        show: false,
        file_name: null,
        form: {
          pickup_time: "",
          pickup_via: 1,
          code: "",
          shipper_addr_id: "",
          sender_name: "",
          sender_addr_id: "",
          sender_address: "",
          sender_postcode: "",
          sender_addr_code: "",
          sender_phone: "",
          order_type: "0",
          pickupend_on: "",
          order_online : 0,
        },
        formItem: {
          //order
          id: "",
          destName: "",
          destTel: "",
          destCity: "",
          receiver_postcode: "",
          addr_id: "",
          addr_detail: "",
          item_name: "",
          weight: "",
          length: "",
          width: "",
          height: "",
          note: "",
          insurance_type: "",
          codValue: 0,
          codStatus: true,
          nilai_ongkir: 0,
          extfield1: "",
          item_qty: 1,
          item_value: 0,
          cod_value: 0,
          destCode: "",
          destZip: "",
          receiver_propinsi: "",
          receiver_kota: "",
          receiver_kecamatan: "",
          courier: "",
          service_code: "",
        },
        submitting1: false,
        submitting2: false,
        og_status: "",
        sender: {},
        droppOffDisabled: false,
        data_excel: [],
        customSwal: {},
      };
    },

    created: function () {
      // alert(this.$route.params.id);
      if (this.$route.params.id) {
        this.loadOrderGroup(this.$route.params.id);
        this.shouldDisable = true;
      } else {
        this.loadAddress();
      }
    },
    computed:{
      disableSubmit: function() {
        return (!this.file_name || this.loading || !this.sender.id);
      }
    },

    methods: {
      downloadKodeXlsx: function () {
        this.loadingDownload2 = true;
        authFetch("/master/destination/code_all_xlsx", {
          method: "GET",
        })
        .then((response) => response.blob())
        .then((blob) => {
          this.loadingDownload2 = false;
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "KODE_TUJUAN.xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      },

      downloadTemplate: function () {
        this.loadingDownload = true;
        authFetch("/file/aset/template", {
          method: "GET",
        })
        .then((response) => response.blob())
        .then((blob) => {
          this.loadingDownload = false;
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "template.xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      },

      onChange(event) {
        this.form.pickup_via = event.target.value;
      },

      onChangeOrderOnline(event) {
        this.form.order_online = event.target.value;
      },

      excelImport(data) {
        var data_upload = [];
        for (var i = 0; i < data.length; i++) {
          let codvalue = 0;
          let status = 0;

          if (!data[i].NAMA_PENERIMA || !data[i].TELEPON || !data[i].ALAMAT_LENGKAP || !data[i].PROPINSI || !data[i].KOTA || !data[i].KECAMATAN || (!data[i].KODE_POS && !data[i].KODE_TUJUAN) || !data[i].NAMA_PRODUK || !data[i].BERAT || !data[i].ASURANSI){
            data_upload = [];
            break;
          } else {
            if (!data[i].NILAI_COD) {
              status = 0;
              codvalue = data[i].HARGA_PRODUK;
            } else {
              status = 1;
              codvalue = data[i].NILAI_COD;
            }

            var courierString = data[i].KURIR.split(" ");

            var aData = {
              id: this.formItem.id,
              destName: data[i].NAMA_PENERIMA,
              order_code: !data[i].ORDER_ID ? "" : data[i].ORDER_ID,
              destTel: !data[i].TELEPON ? "" : data[i].TELEPON,
              // destCode: !data[i].KODE_POS ? "" : data[i].KODE_POS.toString(),
              destCode: !data[i].KODE_TUJUAN ? "" : data[i].KODE_TUJUAN.toString(),
              destZip: !data[i].KODE_POS ? "" : data[i].KODE_POS.toString(),
              receiver_propinsi: !data[i].PROPINSI ? "" : data[i].PROPINSI,
              receiver_kota: !data[i].KOTA ? "" : data[i].KOTA,
              receiver_kecamatan: !data[i].KECAMATAN ? "" : data[i].KECAMATAN,
              addr_id: "",
              addr_detail: !data[i].ALAMAT_LENGKAP ? "" : data[i].ALAMAT_LENGKAP,
              item_name: !data[i].NAMA_PRODUK ? "" : data[i].NAMA_PRODUK,
              item_qty: !data[i].QTY ? 1 : data[i].QTY,
              weight: !data[i].BERAT ? "" : data[i].BERAT,
              length: !data[i].PANJANG ? "" : data[i].PANJANG,
              width: !data[i].LEBAR ? "" : data[i].LEBAR,
              height: !data[i].TINGGI ? "" : data[i].TINGGI,
              note: !data[i].INSTRUKSI ? "" : data[i].INSTRUKSI,
              nilai_ongkir: 0,
              insurance_type: !data[i].ASURANSI ? 1 : (data[i].ASURANSI == 'YES' ? 1 : 0),
              codValue: codvalue,
              codStatus: status,
              extfield1: !data[i].CATATAN_LAIN ? "" : data[i].CATATAN_LAIN,
              item_value: data[i].HARGA_PRODUK,
              cod_value: !data[i].NILAI_COD ? 0 : data[i].NILAI_COD,
              courier: courierString[0],
              service_code: courierString[courierString.length - 1],
            };
            data_upload.push(aData);
          }
        }
        return data_upload;
      },

      excelImportOrderOnlie(data) {
        var data_upload = [];
        for (var i = 0; i < data.length; i++) {
          if (!data[i].name || !data[i].phone || !data[i].address || !data[i].province || !data[i].city || !data[i].subdistrict || !data[i].zip || !data[i].product || !data[i].weight || !data[i].courier || (data[i].name == "" || data[i].phone == "" || data[i].zip == "")){
            data_upload = [];
            break;
          } else {
            let namaLengkap = data[i].name;

            let codvalue = "";
            let status = "";
            if (data[i].cod_cost) {
              status = 1;
              codvalue = data[i].product_price + data[i].shipping_cost;
            } else {
              status = 0;
              codvalue = data[i].product_price;
            }

            let insuranceType = 1;

            let item_qty = 1;
            if(typeof data[i].quantity == 'number') {
              //whatever
              item_qty = data[i].quantity;
            }

            var courierString = data[i].courier.split(" ");

            var aData = {
              id: this.formItem.id,
              order_code: !data[i].order_id ? "" : data[i].order_id,
              destName: namaLengkap,
              destTel: !data[i].phone ? "" : data[i].phone,
              destCode: "",
              destZip: !data[i].zip ? "" : data[i].zip.toString(),
              receiver_propinsi: !data[i].province ? "" : data[i].province,
              receiver_kota: !data[i].city ? "" : data[i].city,
              receiver_kecamatan: !data[i].subdistrict ? "" : data[i].subdistrict,
              addr_id: "",
              addr_detail: !data[i].address ? "" : data[i].address,
              item_name: !data[i].product ? "" : data[i].product,
              item_qty: !data[i].quantity ? 1 : data[i].quantity,
              weight: !data[i].weight ? "" : (data[i].weight/1000),
              length: "",
              width: "",
              height: "",
              item_qty: item_qty,
              note: !data[i].notes ? "" : data[i].notes,
              nilai_ongkir: 0,
              insurance_type: insuranceType,
              codValue: codvalue,
              codStatus: status,
              extfield1: data[i].notes,
              item_value: data[i].product_price,
              cod_value: !data[i].cod_cost ? 0 : data[i].product_price + data[i].shipping_cost,
              courier: courierString[0],
              service_code: courierString[courierString.length - 1],
            };
            data_upload.push(aData);
          }
        }
        return data_upload;
      },
      
      loadAddress(id) {
        let route;
        if (id) {
          route = "/order/address/" + id;
        } else {
          route = "/order/address/0";
        }
        authFetch(route).then((res) => {
          res.json().then((json) => {
            if (json.success) {
              this.sender = json.data;
              this.addrOptions.push({
                id: json.data.addr_id,
                lokasi: json.data.kota + " / " + json.data.kecamatan,
              });
            }
            $('.loading-overlay').removeClass('show');
          });
        });
      },

      loadOrderGroup(id) {
        authFetch("/order/group/" + id).then((res) => {
          res.json().then((json) => {
            this.form = json;

            if (this.form.order_type == "1") {
              this.form.pickup_via = null;
              this.form.pickup_time = null;
              this.form.pickupend_on = null;
            }

            this.og_status = json.og_status;
            this.loadAddress(json.shipper_addr_id);
          });
        });
      },

      selectWarehouse: function () {
        this.show = !this.show;
      },

      submitForm: function (ev) {
        this.$gtag.event(`click`, {
          'event_name': 'Import Order Group'
        });

        const self = this;
        this.form.shipper_addr_id = this.sender.id;
        this.form.sender_name = this.sender.senderName;
        this.form.sender_addr_id = this.sender.addr_id;
        this.form.sender_addr_code = this.sender.addr_code;
        this.form.sender_address = this.sender.addr_detail;
        this.form.sender_postcode = this.sender.kodepos;
        this.form.sender_phone = this.sender.senderTel;
        this.form.code = this.sender.code;

        if (this.form.order_online == 1){
          var items = this.excelImportOrderOnlie(this.data_excel);
        } else {
          var items = this.excelImport(this.data_excel);
        }

        var allData = { group: self.form, items: items };
        this.submitting1 = true;

        console.log("popo ", items);
        if (items.length == 0){
          this.submitting1 = false;
          this.customSwal.fire({
            title: this.form.order_online == 1 ? 'Proses order online gagal' : 'Proses gagal',
            html: 'Data atau kolom tidak sesuai!',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Kembali',
          });
        } else {
          authFetch("/order/import_darft", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(allData),
          })
          .then((res) => {
            this.submitting1 = true;
            return res.json();
          })
          .then((js) => {
            this.submitting1 = false;
            if (js.success == true){
              this.$router.push("/riwayat/draft-kiriman");
            } else {
              if (js.message == "Verify KTP"){
                this.customSwal.fire({
                  html: '<img src="' + this.getImage('verified') + '" class="d-block w-75 m-auto" /><h2 class="swal2-title h4 tex-dark">Verifikasi KTP sekarang untuk dapat<br />menggunakan fitur pesanan COD</h2>',
                  showDenyButton: true,
                  confirmButtonText: 'Verifikasi KTP',
                  denyButtonText: 'Nanti Saja',
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$router.push({ path: "/verification"});
                  }
                });
              } else if (js.message == "Duplicate order code"){
                let detailData = "";
                detailData ='<div style="overflow-y: auto;max-height:150px;" class="error-detail border rounded p-2 mt-2 text-muted text-left">';

                for (let i in js.data_error) {
                  detailData += "<b>Kode "+ js.data_error[i].code +"</b><br><i>"+ js.data_error[i].message +"</i><br><br>";
                }

                detailData += "</div>";
                
                Swal.fire({
                  title: js.message,
                  icon: "error",
                  html: detailData,
                  showCloseButton: true,
                });
              } else {
                this.customSwal.fire({
                  title: js.errors,
                  html: js.message,
                  showConfirmButton: false,
                  showCancelButton: true,
                  cancelButtonText: 'Kembali',
                });
              }
            }
          });
        }
        ev.preventDefault();
      },

      getImage(fileName) {
        return require(`../assets/img/${fileName}.svg`); // the module request
      },
    },

    components: {
      datepicker,
      timepicker,
      Warehouse,
    },

    mounted() {
      const e = this.$refs;
      const self = this;
      var now = moment();
      var minDate = now.startOf("hour").add(1, "h");

      if (now.format("H") >= 20) {
        minDate = minDate.add(1, "d").startOf("date").add(8, "h");
      }

      if (!this.$route.params.id) {
        this.form.pickup_time = minDate.format("YYYY-MM-DD HH:mm:00");
      }

      new Daterangepicker(
        this.$refs.daterange,
        {
          singleDatePicker: true,
          showDropdowns: true,
          timePicker: false,
          timePicker24Hour: false,
          startDate: minDate,
          minDate: minDate,
          locale: {
            format: "DD/MM/YYYY",
          },
        },

        function (d1) {
          if (moment(d1.format("YYYY-MM-DD")).valueOf() == moment(moment().format("YYYY-MM-DD")).valueOf()) {
              self.form.pickup_time = d1.format("YYYY-MM-DD HH:mm:ss");
          } else {
              self.form.pickup_time = d1.format("YYYY-MM-DD 08:00:00");
          }
        }
      );

      Dropzone.autoDiscover = false;

      let myDropzone = new Dropzone(this.$refs.dzone, {
        uploadMultiple: false, 
        maxFiles: 1,
        createImageThumbnails: false,
        autoProcessQueue: false,
        url: 'getMeSomeUrl',
        accept: function(file, done) {
          this.removeFile(file);
        },
        success: function(file){
          this.removeFile(file);
        }
      });

      myDropzone.on("addedfile", file => {
        const reader = new FileReader();
        this.loading = true;
        this.disableSubmit;
        reader.onload = () => {
          var fileData = reader.result;
          var wb = XLSX.read(fileData, { type: "binary" });
          var worksheet = wb.Sheets[wb.SheetNames[0]];

          // if (this.form.order_online == 0){
          //   var range = XLSX.utils.decode_range(worksheet['!ref']);
          //   range.s.r = 1; // <-- zero-indexed, so setting to 1 will skip row 0
          //   worksheet['!ref'] = XLSX.utils.encode_range(range);
          // }

          var rowObj = XLSX.utils.sheet_to_json(worksheet);
          this.data_excel = rowObj;
          
          this.file_name = file.name;
          this.loading = false;
          this.disableSubmit;
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.readAsBinaryString(file);
      });

      myDropzone.on("maxfilesexceeded", function (file) {
          myDropzone.removeAllFiles();
      });

      this.customSwal = Swal.mixin({
        customClass: {
          popup: 'w-25',
          title: 'h4 tex-dark',
          actions: 'd-flex justify-content-between flex-row-reverse',
          confirmButton: 'btn btn-primary w-49 font-weight-bold py-3',
          denyButton: 'btn btn-outline-primary w-49 font-weight-bold py-3',
          cancelButton: 'btn btn-primary btn-block font-weight-bold py-3',
        },
        buttonsStyling: false
      });
    },
  };
</script>

<style scoped>
  .drag-area {
    border: 2px dashed rgb(114, 114, 114);
    min-height: 250px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .drag-area.active {
    border: 2px solid rgb(133, 133, 133);
  }

  .drag-area .icon {
    font-size: 100px;
    color: rgb(139, 139, 139);
  }

  .drag-area header {
    font-size: 30px;
    font-weight: 500;
    color: rgb(126, 126, 126);
  }

  .drag-area span {
    font-size: 25px;
    font-weight: 500;
    color: rgb(129, 129, 129);
    margin: 10px 0 10px 0;
  }

  .drag-area button {
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 500;
    border: none;
    outline: none;
    background: rgb(206, 206, 206);
    color: #5256ad;
    border-radius: 5px;
    cursor: pointer;
  }

  .drag-area img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  .error-detail {
    font-size:12px;
  }
</style>